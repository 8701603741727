@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";
@use "styles/mixins";
@use "styles/variables";

.button {
  position: absolute;
  top: functions.rem(16px);
  right: functions.rem(16px);

  width: functions.rem(48px);
  height: functions.rem(48px);

  border: none;
  border-radius: variables.$rounded-100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: colors.$black;

  svg {
    position: absolute;
    stroke: currentColor;

    width: functions.rem(32px);
    height: functions.rem(32px);
  }
}

@include breakpoints.regular() {
  .button {
    &:hover {
      color: colors.$white;
      background-color: colors.$black;
    }
  }
}
