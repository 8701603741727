@use "styles/colors";
@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.wrapper {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: functions.rem(16px);

  &:focus-within label {
    transform: translate(0, functions.rem(-13px));
  }
}

.wrapperNarrow {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: functions.rem(16px);

  &:focus-within label {
    transform: translate(0, functions.rem(-13px));
  }
}

.input {
  width: 100%;
  height: functions.rem(64px);

  padding: functions.rem(17px) functions.rem(14px) 0;

  font-family: "founders-grotesk-regular";
  font-weight: normal;
  font-size: functions.rem(18px);
  line-height: functions.rem(48px);
  color: colors.$white;

  background-color: colors.$purple;
  border: 2px solid colors.$purple;
  border-radius: variables.$rounded-8;

  &:hover {
    border: 2px solid rgba(colors.$white, 0.3);
    outline: none;
  }

  &:focus {
    border: 2px solid colors.$white;
    outline: none;
  }
}

.inputError {
  @extend .input;

  border: 2px solid colors.$orange;
}

.label {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: functions.rem(16px);

  font-family: "JetBrains Mono";
  font-weight: 700;
  font-size: functions.rem(14px);
  line-height: functions.rem(64px);
  color: colors.$white;

  opacity: 0.3;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.labelFilled {
  @extend .label;
  transform: translate(0, functions.rem(-13px));
}

@include breakpoints.regular() {
  .wrapperNarrow {
    vertical-align: top;

    width: calc(50% - #{functions.rem(8px)});
  }
}
