@use "styles/colors";
@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.wrapper {
  width: 100%;
  margin-bottom: functions.rem(16px);
}

.checkbox {
  display: none;
}

.label {
  display: flex;
  align-items: center;

  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(14px);
  line-height: functions.rem(16px);
  color: colors.$white;
  cursor: pointer;

  &:before {
    content: "";
    display: block;
    width: functions.rem(24px);
    height: functions.rem(24px);
    margin-right: functions.rem(16px);
    position: relative;
    border-radius: variables.$rounded-4;

    flex-grow: 0;
    flex-shrink: 0;

    background-color: #582dc9;
    border: 2px solid rgba(0, 15, 30, 0.1);
  }

  a {
    text-decoration: underline;
  }

  &:hover {
    &:before {
      border: 2px solid rgba(colors.$white, 0.3);
    }
  }
}
.checkbox:checked ~ .label::before {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAALCAYAAABPhbxiAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABTSURBVHgBpc/RCQAgCATQggZxw9qwURzFlPqKC5UEKeieWCmJEhHS7nZvGaTH1DZcw0ibZRefIR/I9kZTPDTQg7seCoT/BII+euAYAjiOLkxebgFNX9W/DEHCCwAAAABJRU5ErkJggg==");
}

.labelError {
  @extend .label;
  color: colors.$orange;

  &:before {
    border-color: colors.$orange;
  }
}

@include breakpoints.regular() {
  .label {
    font-weight: 700;
  }
}
