@use "styles/colors";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/functions";
@use "styles/mixins";

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh-resize, 1vh) * 100);
  overflow: scroll;
  overscroll-behavior: contain;

  color: colors.$white;
  background-color: colors.$black;

  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  position: relative;
  margin: 0 functions.rem(24px);

  @include mixins.heightNavigation();
}

.buttonClose {
  position: absolute;
  right: 0;
  top: functions.rem(20px);
  width: functions.rem(32px);
  height: functions.rem(32px);
  color: colors.$white;
  margin: 0;
  padding: 0;
  border: none;
  background: none;

  > svg {
    stroke: currentColor;
    width: functions.rem(32px);
    height: functions.rem(32px);
  }
}

.list {
  display: block;
  margin: 0;
  padding: 0 functions.rem(24px);
}

.listItem {
  display: flex;
  align-items: center;
  border-bottom: 1px solid colors.$dark-grey;

  &:first-child {
    border-top: 1px solid colors.$dark-grey;
  }
}

.buttonLogo {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;

  // FIXME: create button unset
  background: none;
  border: none;
}

.logo {
  fill: colors.$black;
}

.logoInverted {
  @extend .logo;
  fill: colors.$white;
}

.contact {
  @include mixins.fontLabel();
  border-top: 1px solid colors.$dark-grey;
  padding-top: functions.rem(20px);
  margin: functions.rem(30px) functions.rem(24px) functions.rem(24px);
}

@include breakpoints.regular() {
  .container {
    display: none;
  }
}
