@use "styles/functions";
@use "styles/colors";
@use "styles/mixins";
@use "styles/breakpoints";

.container {
  position: fixed;
  padding: functions.rem(10px) 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid colors.$black;
  color: colors.$black;

  backdrop-filter: grayscale(30%) blur(5px) brightness(90%);
  background-color: rgba(colors.$white, 0.8);

  z-index: 101;

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.content {
  @include mixins.fontLabel();
  display: flex;
  gap: functions.rem(12px);
  justify-content: space-between;
  font-size: functions.rem(12px);
}

@include breakpoints.regular() {
  .content {
    justify-content: center;
  }
}
