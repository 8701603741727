@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";

.cookieConsent {
  position: fixed;
  left: 24px;
  width: calc(100% - 48px);
  top: functions.rem(72px);

  margin: 0 auto;

  z-index: 100;
  pointer-events: none;
}

.inner {
  pointer-events: all;

  padding: functions.rem(20px);

  background-color: colors.$white;

  border-radius: 16px;

  align-items: center;

  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.copy {
  display: inline-block;
  font-family: "founders-grotesk-regular";
  font-weight: 500;
  font-size: functions.rem(18px);
  line-height: functions.rem(24px);
  margin-top: functions.rem(-4px);
  margin-bottom: functions.rem(4px);

  a {
    color: colors.$green;
  }
}

.buttons {
  display: flex;
  justify-content: flex-start;
  gap: functions.rem(16px);

  white-space: nowrap;

  margin-top: functions.rem(20px);
}

@include breakpoints.regular {
  .cookieConsent {
    max-width: 1520px;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;

    margin: 0 auto;
  }

  .inner {
    position: absolute;
    top: auto;
    left: functions.rem(40px);
    bottom: functions.rem(40px);

    width: calc(100% - functions.rem(1020px));
    min-width: functions.rem(320px);
    min-height: functions.rem(232px);

    padding: functions.rem(27px);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .copy {
    position: relative;
    font-size: functions.rem(19px);
    line-height: functions.rem(26px);

    margin-top: functions.rem(-6px);
    margin-bottom: functions.rem(15px);
  }

  .buttons {
    margin-top: 0;
  }
}
