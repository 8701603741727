@use "styles/colors";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/functions";
@use "styles/mixins";

.container {
  @include mixins.heightNavigation();

  display: flex;
  color: colors.$black;

  z-index: 10;
}

.containerInverted {
  @extend .container;
  color: colors.$white;
  background-color: colors.$black;
}

.list {
  display: none;
}

.listItem {
  display: flex;
  align-items: center;
  height: 100%;
}

@include breakpoints.regular() {
  .list {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;
  }
}
