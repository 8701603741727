@use "styles/colors";
@use "styles/variables";
@use "styles/functions";
@use "styles/breakpoints";
@use "styles/mixins";

.form {
  background-color: colors.$purple-lighter;
  border-radius: variables.$rounded-8;
  padding: functions.rem(24px);
}

.formModal {
  @extend .form;
  // padding-top: functions.rem(70px);

  .heading {
    width: calc(100% - #{functions.rem(40px)});
  }
}

.heading {
  margin-bottom: functions.rem(32px);
  color: colors.$white;
}

.inputFields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer {
  display: flex;
  flex-direction: column;
}

.paragraph {
  @include mixins.fontBodySmall;
  color: colors.$white;
  margin-bottom: functions.rem(40px);

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@include breakpoints.regular() {
  .form {
    border-radius: variables.$rounded-16;
    padding: functions.rem(40px);
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    gap: functions.rem(24px);
  }
}
