@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";

.button {
  display: block;
  width: 100%;
  height: functions.rem(100px);
  line-height: functions.rem(100px);
  font-size: functions.rem(28px);
}

.buttonActive {
  @extend .button;

  text-decoration: underline;
}
