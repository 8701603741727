@use "styles/colors";
@use "styles/functions";
@use "styles/variables";

.wrapper {
  position: relative;
  width: 100%;
  margin-bottom: functions.rem(16px);
}

.icon {
  position: absolute;
  right: functions.rem(15px);
  top: functions.rem(16px);
  width: functions.rem(32px);
  height: functions.rem(32px);
  stroke: colors.$white;
}

.wrapperNarrow {
  position: relative;
  width: 100%;
  display: inline-block;
  margin-bottom: functions.rem(16px);
}

.select {
  cursor: pointer;
  width: 100%;
  height: functions.rem(64px);

  padding: functions.rem(17px) functions.rem(60px) 0 functions.rem(14px);

  font-family: "founders-grotesk-regular";
  font-weight: normal;
  font-size: functions.rem(18px);
  line-height: functions.rem(48px);
  color: colors.$white;

  background-color: colors.$purple;
  border: 2px solid colors.$purple;
  border-radius: variables.$rounded-8;

  -webkit-appearance: none;

  &:hover {
    border: 2px solid rgba(colors.$white, 0.3);
    outline: none;
  }

  &:focus {
    border: 2px solid colors.$white;
    outline: none;
  }
}

.selectHidden {
  @extend .select;
  color: rgba(colors.$white, 0);
}

.selectError {
  @extend .select;
  border: 2px solid colors.$orange;
}

.selectErrorHidden {
  @extend .select;
  color: rgba(colors.$white, 0);
}

.label {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: functions.rem(16px);

  font-family: "JetBrains Mono";
  font-weight: 700;
  font-size: functions.rem(14px);
  line-height: functions.rem(64px);
  color: colors.$white;

  opacity: 0.3;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.labelFilled {
  @extend .label;
  transform: translate(0, functions.rem(-13px));
}
