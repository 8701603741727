@use "styles/colors";
@use "styles/mixins";

.button {
  @include mixins.fontLabel();

  display: block;

  align-items: center;

  padding: 20px;

  &:hover {
    color: colors.$green;
  }
}

.buttonInverted {
  @extend .button;

  color: colors.$white;
}

.buttonActive {
  @extend .button;

  text-decoration: underline;
}

.buttonActiveInverted {
  @extend .buttonActive;

  color: colors.$white;
}
