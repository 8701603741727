@use "functions";
@use "variables";
@use "colors";

@import "styles/fonts";
@import "styles/reset";

html,
body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: colors.$background;
}

body {
  overflow: hidden;
  overflow-y: scroll;

  font-weight: normal;
  font-smooth: always;

  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);
}

html {
  font-size: functions.fluidBaseRem(375, 750, 16, 32);
}

@media (min-width: 468px) {
  html {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1024px) {
  html {
    font-size: functions.fluidBaseRem(1260, 1440, 14, 16);
  }
}

@media (min-width: variables.$content-max-width) {
  html {
    font-size: variables.$base-font-size;
  }
}
