@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";

.button {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-flex;

  cursor: pointer;
  outline: inherit;

  padding: 0;
  border: 0;
  border-radius: 0;

  background: none;

  white-space: nowrap;
}

.regular {
  display: inline-flex;
  align-items: center;

  border: 2px solid colors.$black;
  border-radius: 0;

  background: none;
  background-color: transparent;
  padding: 0 functions.rem(24px);

  color: colors.$black;
  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(16px);
  height: functions.rem(64px);
  line-height: functions.rem(62px);
  text-transform: uppercase;
}

.hover {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: none;
  align-items: center;

  border: 2px solid colors.$black;
  border-radius: 0;

  background: none;
  background-color: transparent;
  padding: 0 functions.rem(24px);

  color: colors.$green;
  font-family: "JetBrains Mono";
  font-weight: 500;
  font-size: functions.rem(16px);
  height: functions.rem(64px);
  line-height: functions.rem(62px);
  text-transform: uppercase;

  border-color: colors.$green;
  background-color: colors.$white;

  transition: clip-path 300ms cubic-bezier(0.42, 0, 0.58, 1);
}

.buttonFilled {
  @extend .button;

  .regular {
    color: colors.$white;
    background-color: colors.$black;
  }

  .hover {
    color: colors.$white;
    background-color: colors.$green;
  }
}

.buttonFilledOnPurple {
  @extend .button;

  .regular {
    color: colors.$black;
    border-color: colors.$white;
    background-color: colors.$white;
  }

  .hover {
    color: colors.$white;
    border-color: colors.$black;
    background-color: colors.$black;
  }
}

.icon {
  flex-grow: 0;
  flex-shrink: 0;
  fill: currentColor;
  margin-left: functions.rem(12px);
  margin-right: functions.rem(-10px);
  width: functions.rem(32px);
  height: functions.rem(32px);
}

.iconDown {
  @extend .icon;
  transform: rotate(90deg);
}

.iconLeft {
  @extend .icon;
  transform: rotate(180deg);
}

.iconUp {
  @extend .icon;
  transform: rotate(-90deg);
}

@include breakpoints.regular() {
  .button {
    .hover {
      clip-path: polygon(0 0, 0% 0, 0% 100%, 0 100%);
    }

    &:hover {
      .hover {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }
  .hover {
    display: inline-flex;
  }

  // .buttonFilled {
  //   &:hover {
  //     color: colors.$white;
  //     background-color: colors.$green;
  //   }
  // }

  // .buttonFilledOnPurple {
  //   &:hover {
  //     color: colors.$white;
  //     border-color: colors.$black;
  //     background-color: colors.$black;
  //   }
  // }
}
