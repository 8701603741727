@use "styles/colors";
@use "styles/functions";
@use "styles/variables";

.wrapper {
  margin-top: functions.rem(16px);
  margin-bottom: functions.rem(16px);

  font-family: "JetBrains Mono";
  font-weight: 700;
  font-size: functions.rem(14px);
  line-height: functions.rem(18px);
  color: colors.$white;
}
