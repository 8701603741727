@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.container {
  position: fixed;
  padding: functions.rem(48px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(colors.$light-grey, 0.75);

  display: flex;
  align-content: center;
  justify-content: center;
  align-items: flex-start;

  overflow: auto;

  z-index: 10;
}

@include breakpoints.regular() {
  .container {
  }
}
