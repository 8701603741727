@use "styles/colors";
@use "styles/functions";
@use "styles/breakpoints";

.buttonLogo {
  position: absolute;
  top: functions.rem(25px);
  bottom: 0;
  margin: 0;

  width: functions.rem(142px);
  height: functions.rem(24px);

  // FIXME: create button unset
  background: none;
  border: none;
}

.logo {
  width: functions.rem(142px);
  height: functions.rem(24px);
  fill: colors.$black;
}

.logoInverted {
  @extend .logo;
  fill: colors.$white;
}

@include breakpoints.regular() {
  .buttonLogo {
    top: functions.rem(34px);
  }
}
