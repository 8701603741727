@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";

.container {
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;

  width: 100%;
}

.containerOverflow {
  @extend .container;
  overflow: visible;
}

.content {
  position: relative;

  width: functions.rem(327px);
  max-width: functions.rem(327px);
}

@include breakpoints.regular {
  .content {
    width: calc(100% - functions.rem(80px));
    max-width: functions.rem(variables.$content-max-width);
  }
}
