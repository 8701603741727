@use "styles/functions";
@use "styles/variables";
@use "styles/breakpoints";
@use "styles/colors";
@use "styles/mixins";

.container {
  position: relative;
  width: functions.rem(327px);
}

@include breakpoints.regular() {
  .container {
    width: functions.rem(670px);
  }
}
