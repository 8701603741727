@font-face {
  font-family: "JetBrains Mono";
  font-weight: 400;
  font-style: normal;
  src: url("/assets/fonts/jetbrains-mono-v11-latin-regular.woff2");
}
@font-face {
  font-family: "JetBrains Mono";
  font-weight: 500;
  font-style: normal;
  src: url("/assets/fonts/jetbrains-mono-v11-latin-500.woff2");
}
@font-face {
  font-family: "JetBrains Mono";
  font-weight: 600;
  font-style: normal;
  src: url("/assets/fonts/jetbrains-mono-v11-latin-600.woff2");
}
@font-face {
  font-family: "JetBrains Mono";
  font-weight: 700;
  font-style: normal;
  src: url("/assets/fonts/jetbrains-mono-v11-latin-700.woff2");
}

@font-face {
  font-family: "founders-grotesk-regular";
  font-weight: normal;
  src: url("/assets/fonts/founders-grotesk-regular.woff2");
}

@font-face {
  font-family: "founders-grotesk-regular-italic";
  font-weight: normal;
  src: url("/assets/fonts/founders-grotesk-regular-italic.woff2");
}

@font-face {
  font-family: "founders-grotesk-semibold";
  font-weight: normal;
  src: url("/assets/fonts/founders-grotesk-semibold.woff2");
}

@font-face {
  font-family: "founders-grotesk-semibold-italic";
  font-weight: normal;
  src: url("/assets/fonts/founders-grotesk-semibold-italic.woff2");
}
