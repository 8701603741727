@use "styles/functions";
@use "styles/breakpoints";
@use "styles/colors";

.button {
  cursor: pointer;
  outline: inherit;

  border: 0;
  border-radius: 0;

  background: none;
  background-color: transparent;

  position: absolute;
  top: functions.rem(20px);
  right: 0;

  margin: 0;
  padding: 0;
}

.icon {
  width: functions.rem(32px);
  height: functions.rem(32px);
  stroke: colors.$black;
}

.iconInverted {
  @extend .icon;
  stroke: colors.$white;
}

@include breakpoints.regular() {
  .button {
    display: none;
  }
}
